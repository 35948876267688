import React, { Fragment } from 'react';
import { clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Popover } from '@headlessui/react';
import { faCircleQuestion, faHandHoldingBox, faWarehouseFull } from '@fortawesome/pro-light-svg-icons';

import { NavItem, NavItemProps } from './NavItem';
import logoSpacefill from './logo-spacefill.svg';

export type WarehouseItemSubSection = 'orders' | 'todo' | 'stock' | 'conf' | 'bill';

export interface WarehouseItemProps {
    disabled?: boolean;
    isOpen?: boolean;
    subItems: NavItemProps[];
    title: string;
    dataTestid?: string;
    htmlAttributes?: React.HTMLAttributes<HTMLLIElement>;
    logoUrl?: string;
}

export interface UserInfoProps {
    userName: string;
    company: string;
    role: string;
}

export interface SidebarProps {
    mainNavigation?: NavItemProps[];
    warehouseNavigation?: WarehouseItemProps[];
    supportNavigation?: NavItemProps[];
    bottomNavigation?: NavItemProps[];
    profileTopNavigation?: NavItemProps[];
    profileNavigation?: NavItemProps[];
    userInfo: UserInfoProps;
}

export const Sidebar = ({
    mainNavigation = [],
    warehouseNavigation = [],
    supportNavigation = [],
    bottomNavigation = [],
    profileNavigation = [],
    profileTopNavigation = [],
    userInfo,
}: SidebarProps) => {
    const { t } = useTranslation();

    return (
        <nav
            role='navigation'
            className='flex h-full flex-col justify-between bg-zinc-900'
        >
            <div
                className='overflow-y-auto bg-zinc-900'
                // Force dark mode on sidebar to have a black scrollbar
                style={{ colorScheme: 'dark' }}
            >
                <img
                    className='py-6 pl-4 pr-16'
                    alt='logo'
                    src={logoSpacefill}
                />
                <ul className='flex flex-col gap-1 bg-zinc-900 px-2'>
                    {mainNavigation.map((props, idx) => (
                        <NavItem
                            {...props}
                            key={`nav-item-${idx}`}
                        />
                    ))}
                </ul>
                <div className='mt-6 bg-zinc-900 px-2 text-sm'>
                    <div className='my-4 text-xs text-zinc-500'>{t('Warehouses') as string}</div>
                    <ul>
                        {warehouseNavigation.map(({ isOpen, dataTestid, logoUrl, subItems, ...props }, idx) => (
                            <NavItem
                                key={`warehouse-item-${idx}`}
                                isExpandable={true}
                                isActive={isOpen}
                                dataTestid={`${dataTestid}-title`}
                                logoUrl={logoUrl ?? ''}
                                {...props}
                            >
                                <ul
                                    data-testid='sidebar-navitem'
                                    className={`mb-2 origin-top rounded-b-md bg-zinc-800 pl-2 transition ${
                                        isOpen ? 'scale-y-100 duration-300' : 'h-0 scale-y-0 duration-0'
                                    }`}
                                >
                                    {subItems.map((subItem: NavItemProps, subIdx) => (
                                        <NavItem
                                            icon={subItem.icon}
                                            key={`warehouse-${idx}-subitem-${subIdx}`}
                                            title={subItem.title}
                                            variant='soft'
                                            isProFeature={subItem.isProFeature}
                                            countValue={subItem.countValue}
                                            isActive={subItem.isActive}
                                            dataTestid={subItem.dataTestid}
                                            to={subItem.to}
                                            href={subItem.href}
                                            target={subItem.target}
                                            onClick={subItem.onClick}
                                        />
                                    ))}
                                </ul>
                            </NavItem>
                        ))}
                    </ul>
                </div>
            </div>
            <div className='mt-auto border-t border-solid border-t-zinc-600 bg-zinc-900'>
                <Popover className='relative w-full'>
                    {({ open }) => (
                        <Fragment>
                            <Popover.Button
                                as='div'
                                className={clsx(
                                    [
                                        'm-2 flex list-none items-center gap-2 rounded-md text-sm font-medium text-white hover:cursor-pointer',
                                    ],
                                    {
                                        'bg-zinc-700': open,
                                        'hover:bg-zinc-800': !open,
                                    }
                                )}
                            >
                                <NavItem
                                    icon={<FontAwesomeIcon icon={faCircleQuestion} />}
                                    title='Support'
                                />
                            </Popover.Button>
                            <Popover.Panel className='absolute bottom-0 left-full z-40 mx-4 w-full rounded-md bg-zinc-900 p-2'>
                                <ul>
                                    {supportNavigation.map((props, idx) => (
                                        <NavItem
                                            {...props}
                                            key={`support-item-${idx}`}
                                        />
                                    ))}
                                </ul>
                            </Popover.Panel>
                        </Fragment>
                    )}
                </Popover>
                <ul className='bg-zinc-900 px-2'>
                    {bottomNavigation.map((props, idx) => (
                        <NavItem
                            {...props}
                            key={`bottom-item-${idx}`}
                        />
                    ))}
                </ul>
                <Popover className='relative w-full'>
                    {({ open }) => (
                        <Fragment>
                            <Popover.Button
                                as='div'
                                className={clsx(
                                    [
                                        'm-2 flex items-center gap-4 rounded-md p-2 text-sm font-medium text-white hover:cursor-pointer hover:font-bold',
                                    ],
                                    {
                                        'bg-zinc-700': open,
                                        'bg-zinc-800': !open,
                                    }
                                )}
                            >
                                <FontAwesomeIcon
                                    icon={userInfo.role === 'SHIPPER' ? faHandHoldingBox : faWarehouseFull}
                                    className='w-3.5 rounded-full bg-zinc-700 p-2 text-white'
                                />
                                <div
                                    className='truncate'
                                    data-testid='user-info'
                                >
                                    <p data-testid='name'>{userInfo.userName}</p>
                                    <p
                                        className='text-xs text-zinc-400'
                                        data-testid='company'
                                    >
                                        {userInfo.company}
                                    </p>
                                </div>
                            </Popover.Button>
                            <Popover.Panel className='absolute bottom-0 left-full z-40 mx-4 w-full rounded-md bg-zinc-900 p-2'>
                                {profileTopNavigation.length > 0 && (
                                    <Fragment>
                                        <ul>
                                            {profileTopNavigation.map((props, idx) => (
                                                <NavItem
                                                    {...props}
                                                    key={`profile-top-item-${idx}`}
                                                />
                                            ))}
                                        </ul>
                                        <hr className='border-zinc-600' />
                                    </Fragment>
                                )}
                                <ul>
                                    {profileNavigation.map((props, idx) => (
                                        <NavItem
                                            {...props}
                                            key={`profile-item-${idx}`}
                                        />
                                    ))}
                                </ul>
                            </Popover.Panel>
                        </Fragment>
                    )}
                </Popover>
            </div>
        </nav>
    );
};
