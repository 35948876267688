import React, { ReactNode, MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clsx } from 'clsx';
import { faAwardSimple, faChevronUp } from '@fortawesome/pro-light-svg-icons';
import { Link, To } from 'react-router-dom';

import { CompanyLogo } from '../CompanyLogo';
import { ChipNumber } from '../ChipNumber';

export interface LinkWrapperProps {
    href?: string;
    to?: To;
    target?: string;
    onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
    children?: ReactNode;
    dataTestid?: string;
    className?: string;
}

function LinkWrapper({ to, href, target, onClick, children, dataTestid, className }: LinkWrapperProps) {
    if (to)
        return (
            <Link
                to={to}
                className={className}
                data-testid={dataTestid}
            >
                {children}
            </Link>
        );

    if (href)
        return (
            <a
                href={href}
                target={target}
                className={className}
                data-testid={dataTestid}
                onClick={onClick}
            >
                {children}
            </a>
        );
    else
        return (
            <a
                className={className}
                data-testid={dataTestid}
                onClick={onClick}
            >
                {children}
            </a>
        );
}

export interface NavItemProps {
    showIf?: boolean;
    disabled?: boolean;
    isActive?: boolean;
    isProFeature?: boolean;
    isExpandable?: boolean;
    href?: string;
    to?: To;
    target?: string;
    onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
    variant?: 'default' | 'soft';
    title: string;
    children?: ReactNode;
    icon?: React.ReactElement<typeof FontAwesomeIcon>;
    hasRoundedIcon?: boolean;
    dataTestid?: string;
    htmlAttributes?: React.HTMLAttributes<HTMLLIElement>;
    logoUrl?: string;
    countValue?: number;
}

export const NavItem = ({
    showIf = true,
    disabled = false,
    isProFeature = false,
    isActive = false,
    isExpandable = false,
    variant = 'default',
    title,
    children,
    icon,
    hasRoundedIcon = false,
    dataTestid = 'nav-item',
    logoUrl = undefined,
    countValue = undefined,
    onClick,
    to,
    href,
    target,
    htmlAttributes,
}: NavItemProps) =>
    showIf ? (
        <li
            className='flex-1 cursor-pointer'
            {...htmlAttributes}
        >
            <LinkWrapper
                dataTestid={dataTestid}
                className={clsx(['h-12', 'text-sm', 'py-3.5', 'px-2', 'gap-2', 'flex', 'items-center'], {
                    'text-zinc-500 hover:font-normal cursor-not-allowed': disabled,
                    'hover:font-normal cursor-not-allowed': isProFeature,
                    'text-white hover:bg-zinc-800': !isActive,
                    'rounded-t-md': isExpandable,
                    'rounded-md': !isExpandable,
                    'hover:font-semibold': variant === 'soft',
                    'text-lime-300 bg-zinc-800 font-semibold': variant === 'soft' && isActive,
                    'bg-lime-300 text-black': variant !== 'soft' && isActive,
                })}
                to={to}
                href={href}
                target={target}
                onClick={onClick}
            >
                {icon && (
                    <div
                        className={clsx({
                            'bg-zinc-700 rounded-full p-2 h-8 w-8 text-white flex': hasRoundedIcon,
                        })}
                    >
                        {icon}
                    </div>
                )}
                {logoUrl !== undefined && (
                    <CompanyLogo
                        logoUrl={logoUrl}
                        fromNavBar
                        isActive={isActive}
                    ></CompanyLogo>
                )}
                {title}
                {isProFeature && (
                    <FontAwesomeIcon
                        icon={faAwardSimple}
                        className='text-violet-400'
                    />
                )}
                {countValue !== undefined && (
                    <ChipNumber
                        variant={'red'}
                        value={countValue}
                    />
                )}
                <div
                    className='flex w-full flex-1 items-center justify-end gap-2 hover:font-normal'
                    data-testid={`${dataTestid}-count`}
                >
                    {isExpandable && (
                        <FontAwesomeIcon
                            icon={faChevronUp}
                            className={`transition-transform duration-300 ${isActive ? 'rotate-0' : 'rotate-180'}`}
                        />
                    )}
                </div>
            </LinkWrapper>
            {children}
        </li>
    ) : null;
